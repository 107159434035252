// Sentry
import * as Sentry from '@sentry/browser';

// fonts
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
// styles
import '@/stylesheets/shop/application.scss';
// libraries
import 'bootstrap/dist/js/bootstrap';
import 'admin-lte/dist/js/adminlte';

// rails-ujs
import Rails from '@rails/ujs';

// images
require.context('@/images/shop', true);
require.context('@/images/shared', true);

Rails.start();

document.addEventListener('DOMContentLoaded', () => {
  const sentryDsnMetaElement = document.querySelector<HTMLMetaElement>('meta[name="sentry-dsn"]');
  const environmentElement = document.querySelector<HTMLMetaElement>('meta[name="environment"]');
  if (sentryDsnMetaElement && environmentElement) {
    Sentry.init({
      dsn: sentryDsnMetaElement.content,
      environment: environmentElement.content,
      integrations: [Sentry.browserTracingIntegration()],
    });
    Sentry.setTag('application', 'mart-server-shop');
    Sentry.setTag('language', 'JavaScript');
  }
});
